import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsfService } from 'src/app/services/isf.service';
import { SnackbarService } from 'src/app/services/error-snackbar.service';


@Component({
  selector: 'edit_work_session_name_dialog',
  template: `
    <h1 mat-dialog-title>Actualizar nombre de jornada: {{data.old_name}}</h1>
    <div mat-dialog-content class="dialog-content">

      <div class="alert alert-warning">
        Sólo se usarán los primeros 30 caracteres para impactar en salesforce. El resto sólo serán visibles en CORA.
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Nombre</mat-label>
        <input matInput [(ngModel)]="data.new_name">
      </mat-form-field>

      <div class="alert alert-error" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div mat-dialog-actions>
        <button mat-button (click)="onClose()" cdkFocusInitial>Cerrar</button>
        <button mat-button (click)="onUpdate()" class="confirm-button">Actualizar</button>
      </div>
    </div>
    
  `,
  styleUrls: ['./update-work-session-name-dialog.component.scss']
})

export class UpdateWorkSessionNameDialogComponent {
  constructor(
    private _isfService: IsfService,
    public dialogRef: MatDialogRef<UpdateWorkSessionNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateWorkSessionNameDialogData,
  ) {
    this.errorMessage = null;
  }

  errorMessage: string | null = null

  inputsAreValid(): boolean {
    this.errorMessage = '';
    if (!this.data.new_name){ this.errorMessage += 'Nombre requerido. '; }
    return !this.errorMessage;
  }

  onUpdate(): void {

    // Validar fechas
    if (!this.inputsAreValid()){
      return;
    }

    try {
      this._isfService.updateWorkSession(this.data.work_session_id, 
        { name: this.data.new_name}
      ).then((response) => {
        if (response){
          SnackbarService.showSuccess('Nombre de jornada actualizada!');
          this.dialogRef.close({new_name: this.data.new_name});
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  onClose(): void {
    this.dialogRef.close(null);
  }
}

export interface UpdateWorkSessionNameDialogData {
  work_session_id: string;
  old_name: string;
  new_name: string;
}
