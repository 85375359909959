import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MaterialModule } from './material-module';
import { IsfService } from './services/isf.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProjectContainerComponent } from './components/project-container/project-container.component';
import { ProjectInfoComponent, CreateWorkSessionDialog } from './components/project-info/project-info.component';
import { ParticipantDialogComponent } from './components/participant/participant-dialog.component';
import { CreateParticipantDialogComponent } from './components/participant/create-participant-dialog.component';
import { AngularFireModule} from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AddParticipantDialog, WorkSessionComponent } from './components/work-session/work-session.component';
import { UpdateWorkSessionNameDialogComponent } from './components/work-session/update-work-session-name-dialog.component';
import { WorkSessionFormComponent } from './components/work-session-form/work-session-form.component';
import { WorkSessionFormStageOneComponent } from './components/work-session-form/work-session-form-stage-one.component';
import { WorkSessionFormStageTwoComponent } from './components/work-session-form/work-session-form-stage-two.component';
import { WorkSessionFormStageThreeComponent } from './components/work-session-form/work-session-form-stage-three.component';
import { WorkSessionFormStageFourComponent } from './components/work-session-form/work-session-form-stage-four.component';
import { IsSignedInGuard } from './services/sign-in-guard.service';
import { SnackbarService } from './services/error-snackbar.service';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    NavigationItemComponent,
    DashboardComponent,
    ProjectContainerComponent,
    ProjectInfoComponent,
    ParticipantDialogComponent,
    LoginComponent,
    WorkSessionComponent,
    WorkSessionFormComponent,
    WorkSessionFormStageOneComponent,
    WorkSessionFormStageTwoComponent,
    WorkSessionFormStageThreeComponent,
    WorkSessionFormStageFourComponent,
    UpdateWorkSessionNameDialogComponent,
    CreateWorkSessionDialog,
    CreateParticipantDialogComponent,
    AddParticipantDialog
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase_config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [IsfService,
    AuthService,
    LoaderService,
    AuthGuardService,
    IsSignedInGuard,
    SnackbarService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: PERSISTENCE, useValue: 'session'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
