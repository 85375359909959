import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './error-snackbar.service';


export interface ProjectIdentifiers {
  id: string,
  name: string
}

export interface Enrollment {
  contact_id: string,
  diet?: string,
  transport?: string,
  location?: string,
  comments?: string,
  contact: {
      name: string,
      lastname: string,
      email: string,
      dni: string,
      phone?: string,
      birthdate?: string,
      pronoun?: string
  }
}

export interface ContactDto {
  id: string;
  name: string;
  lastname: string;
  pronoun: string;
  dni: string;
  email: string;
  phone: string;
  birthdate: string;
}
export interface CreateParticipantDto {
  role: string;
  date_init: string;
  contact: ContactDto;
}

export interface WorkSession {
  id: string,
  implementation_date: string,
  is_sync: boolean,
  name: string,
  registered_participants: number,
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class IsfService {
  private _urlBE: string;
  private _me: any;
  private nameLogin: string;
  private lastNameLogin: string;

  projectsInfo: Record<string, any> = {};
  activeWorkSession = {};

  private _httpOptions: any = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  /**
   * Genera mensaje de error a partir de una excepcion devuelta por la api (backend)
   * @param error Error excepcion
   * @param defaultError Mensaje por defecto en caso que no se pueda obtener de la excepcion
   * @returns Mensaje de error (string)
   */
  private _getError(error: any, defaultError: string) : string{
    if (error?.error?.message?.length > 0 && error?.error?.message[0].errors?.length > 0){
      // Error de salesforce
      return error?.error?.message[0].errors[0].message;
    }
    // Error api o default
    return error?.error?.message ?? defaultError;
  }

  constructor(private _http: HttpClient) {
    this._urlBE = environment.config.urlBE;
  }

  async init() {
      try {
        var projects = await this.getAllProjects();
      } catch (error) {
        console.log(error);
      };
      for(let project of projects){
          this.projectsInfo[project['id']] = project;
      }
  }

  private convertToJson(body: Map<string, string>) {
    const json: { [id: string]: string; } = {};
    body.forEach((val, mapKey) => {
      json[mapKey] = val
    })
    return json
  }

  private handleError(error: string) {
    SnackbarService.showError(error);
    return throwError(() => new Error('Ocurrió un error. Por favor reintentar mas tarde.'));
  }

  public async getAllProjects(): Promise<any> {
    const url = `${this._urlBE}/projects`;
    return await this._http.get(url, this._httpOptions).toPromise();
  }

  public async syncProjects(): Promise<any> {
      const url = `${this._urlBE}/sync/projects`;
      return await this._http.post(url, this._httpOptions).toPromise()
  }

  public async getAllProjectsIdentifiers(): Promise<ProjectIdentifiers[]> {
    let keys = Object.keys(this.projectsInfo);
    let identifiers: ProjectIdentifiers[] = [];
    for(let key of keys) {
      let projectName: string = this.projectsInfo[key]['name'];
      identifiers.push({id: key, name: projectName});
    }
    return identifiers;
  }

  public async getAllProjectsNames(): Promise<string[]> {
    return Object.keys(this.projectsInfo);
  }

  public async getProjectInfo(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener la información del proyecto");
      this.handleError(errorMessage);
    }
  }

  public async getAllWorksSessionsByProject(id: string, active: boolean): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/work-sessions` + (active? '?active=true' : '');
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener las jornadas del proyecto");
      this.handleError(errorMessage);
    }
  }

  public async getAllParticipantsByProject(id: string, updating: boolean = false): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/participants`;
      if (updating) {
        return await this._http.patch(url, this._httpOptions).toPromise();
      } else {
        return await this._http.get(url, this._httpOptions).toPromise();
      }
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener todos los participantes del proyecto");
      this.handleError(errorMessage);
    }
  }

  /**
   * Crea una participación nueva en un proyecto. Si el contacto ya existe en el proyecto (aunque sea inactivo), habrá error. 
   * Si es un contacto nuevo, completar todos los datos de contacto para darlo de alta en CORA y Salesforce.
   * @param projectId Proyecto al cual agregar la participación
   * @param participant Rol + Contacto. Si solo se provee el DNI, intentará buscarlo. Si no existe, devuelve error. Para crear contacto enviar datos completos (no solo DNI)
   * @returns objeto participacion? TODO...
   */
  public async createParticipantOnProject(projectId: string, participant: CreateParticipantDto): Promise<any> {
    const body = JSON.stringify(participant);
    try {
      const url = `${this._urlBE}/projects/${projectId}/participants`;
      return await this._http.post(url, body, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo al intentar registrar el participante");
      this.handleError(errorMessage);
    }
  }

  public async getWorkSessionParticipantsById(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener participantes de la jornada");
      this.handleError(errorMessage);
    }
  }

  public async updateWorkSession(id: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar actualizar la jornada");
      this.handleError(errorMessage);
    }
  }

  public async saveWorkSessionAttendance(id: string, attendance: Array<Map<string,string>>, syncCloud:boolean = false): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance?sync=${syncCloud}`;
      const body = {
        "attendance": attendance.map((att, i, z) =>
          this.convertToJson(att)
        )
      }
      return await this._http.patch(url, body, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar guardar las asistencias de la jornada");
      this.handleError(errorMessage);
    }
  }

  public async createWorkSession(projectId: string, name: string, date: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${projectId}/work-sessions`;
      const body = { "name": name, "date": date }
      return await this._http.post(url, body, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar crear la jornada");
      this.handleError(errorMessage);
    }
  }

  public async getWorkSessionInfo(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Hubo un error al obtener información de la jornada");
    }
  }

  public setActiveWorkSession(workSession: any) {
    this.activeWorkSession = workSession;
  }

  public getActiveWorkSession(): any {
    return this.activeWorkSession;
  }

  public normalizeWorkSessionName(name: string, urlFriendly: boolean = false): string {
    let splitted = name.split(": ", 2);
    let splittedName = splitted.length > 1? splitted[1] : splitted[0];
    if (urlFriendly) {
      return splittedName.replace('/', '\\');
    }
    return splittedName;
  }

  public async getContactByDni(dni: string, token: string): Promise<any> {
    const url = `${this._urlBE}/contacts/${dni}`;
    try {
      const headers = { headers: this._httpOptions.headers.set('Authorization', 'Bearer ' + token) }
      return await this._http.get(url, headers).toPromise();
    } catch (error) {
      this.handleError("Hubo un error al initentar obtener el contacto");
    }
  }

  public async getContactByDniAdmin(dni: string): Promise<any> {
    const url = `${this._urlBE}/contacts/${dni}`;
    try {
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al initentar buscar el contacto");
      this.handleError(errorMessage);
    }
  }

  public async getEnrollmentTokensForProject(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/enrollment`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener los tokens de jornadas");
      this.handleError(errorMessage);
    }
  }

  public async updateEnrollmentForWorkSession(projectId: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${projectId}/enrollment`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar actualizar la asistencia");
      this.handleError(errorMessage);
    }
  }

  public async validateTokenForWorkSessionEnrollment(token: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/enrollment/${token}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Hubo un error al intentar validar el token de inscripción");
    }
  }

  public async saveEnrollmentToWorkSession(id: string, enrollment: Enrollment, token: string, isNewContact:boolean = false): Promise<any> {
    let body;
    if (isNewContact) {
      body = JSON.stringify(enrollment);
    } else {
      const keysToInclude = ['contact_id', 'diet', 'transport', 'location', 'comments'];

      const filteredEnrollment = Object.keys(enrollment).reduce((obj, key) => {
        if (keysToInclude.includes(key)) {
          obj[key] = enrollment[key as keyof Enrollment];
        }
        return obj;
      }, {} as Record<string, any>);

      body = JSON.stringify(filteredEnrollment);
    }

    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      const headers = { headers: this._httpOptions.headers.set('Authorization', 'Bearer ' + token) }
      return await this._http.post(url, body, headers).toPromise();
    } catch (error) {
      this.handleError("Hubo un error al intentar registrarse");
    }
  }

  public async addParticipantToWorkSession(id: string, contact: string): Promise<any> {
    let body: Record<string, string> = {contact_id: contact};
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      return await this._http.post(url, body, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar agregar participante a la jornada");
      this.handleError(errorMessage);
    }
  }

  public async updateParticipant(id: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/participants/${id}`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar actualizar participante");
      this.handleError(errorMessage);
    }
  }

  public async getParticipantFull(participantId: string): Promise<any> {
    try {
      const url = `${this._urlBE}/participants/full/${participantId}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar obtener datos del participante full");
      this.handleError(errorMessage);
    }
  }

  /**
   * Actualiza participacion (+ program engagement) actual, sin crear nuevas
   * @param participantId Id de participation
   * @param data data de la participación (+ program engagement)
   * @returns objeto Participant actualizado
   */
  public async updateParticipantFull(participantId: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/participants/full/${participantId}`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error: any) {
      const errorMessage = this._getError(error, "Hubo un error al intentar actualizar datos del participante full");
      this.handleError(errorMessage);
    }
  }
}
