import { Injectable, EventEmitter } from "@angular/core";

@Injectable({providedIn: 'root'})
export class SnackbarService {

    static errorEvent = new EventEmitter<any>();
    static successEvent = new EventEmitter<any>();
 
    static showError(errorMessage: string) {
        SnackbarService.errorEvent.emit(errorMessage);
    }

    static showSuccess(successMessage: string) {
        SnackbarService.successEvent.emit(successMessage);
    }
}