import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactDto, CreateParticipantDto, IsfService } from 'src/app/services/isf.service';
import { CoordinationRoleOptions, ExtraRoleOptions, getDateStringForBackend, ParticipantRoleOptions, Pronouns } from 'src/app/utils/utils';
import { SnackbarService } from 'src/app/services/error-snackbar.service';


@Component({
  selector: 'create_participant_dialog',
  template: `
    <h1 mat-dialog-title>Crear participación nueva en proyecto</h1>
    <div mat-dialog-content class="dialog-content">

      <!-- <mat-card> -->
        <mat-form-field appearance="fill">
          <mat-label>Rol</mat-label>
          <mat-select [(ngModel)]="data.role">
            <mat-option *ngFor="let option of participantRoleOptions" [value]="option">{{ option }}</mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let option of coordinationRoleOptions" [value]="option">{{ option }}</mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let option of extraRoleOptions" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput readonly [matDatepicker]="dateInit" [(ngModel)]="data.date_init" placeholder="Fecha inicio participación">
          <mat-datepicker-toggle matSuffix [for]="dateInit"></mat-datepicker-toggle>
          <mat-datepicker touchUi #dateInit></mat-datepicker>
        </mat-form-field>
      <!-- </mat-card> -->

      <mat-card>
        <div class="alert alert-warning">
          Ingresar DNI. Si no existe en sistema, se pedirán datos completos para dar de alta al voluntario/a.
        </div>
        <mat-form-field appearance="fill">
          <mat-label>DNI</mat-label>
          <input matInput [(ngModel)]="data.contact.dni" inputmode="numeric" #dni [disabled]="createMode">
          <label class="label-warning">Este dato es clave. Revisar que sea correcto!</label>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <mat-label>Nombre</mat-label>
          <input matInput [(ngModel)]="data.contact.name" [disabled]="contactFound">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <mat-label>Apellido</mat-label>
          <input matInput [(ngModel)]="data.contact.lastname" [disabled]="contactFound">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <input matInput readonly [(ngModel)]="data.contact.birthdate" [disabled]="contactFound" [matDatepicker]="birthDate" placeholder="Fecha nacimiento">
          <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
          <mat-datepicker touchUi #birthDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="data.contact.email" [disabled]="contactFound">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <mat-label>Teléfono</mat-label>
          <input matInput [(ngModel)]="data.contact.phone" [disabled]="contactFound">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="createMode">
          <mat-label>Pronombre</mat-label>
          <mat-select [disabled]="contactFound" [(ngModel)]="data.contact.pronoun">
            <mat-option *ngFor="let pronoun of pronouns" [value]="pronoun.value">{{ pronoun.viewValue }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card>

      <div class="alert alert-error" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div mat-dialog-actions>
        <button mat-button (click)="onClose()" cdkFocusInitial>Cerrar</button>
        <button mat-button (click)="onSearch()" class="confirm-button" *ngIf="!createMode">Buscar</button>
        <button mat-button (click)="onCreate()" class="confirm-button" *ngIf="createMode">Crear</button>
      </div>
    </div>
    
  `,
  styleUrls: ['./create-participant-dialog.component.scss']
})

export class CreateParticipantDialogComponent {
  constructor(
    private _isfService: IsfService,
    public dialogRef: MatDialogRef<CreateParticipantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateParticipantDialogData,
  ) {
    this.createMode = false
    this.contactFound = false
    this.errorMessage = null;
  }

  createMode: boolean
  contactFound: boolean
  errorMessage: string | null = null

  participantRoleOptions = ParticipantRoleOptions;
  coordinationRoleOptions = CoordinationRoleOptions;
  extraRoleOptions = ExtraRoleOptions;
  pronouns = Pronouns;

  inputsAreValid(): boolean {
    this.errorMessage = '';
    if (!this.data.role){ this.errorMessage += 'Rol requerido. '; }
    if (!this.data.date_init){
      this.errorMessage += 'Fecha de inicio requerida. ';
    } else {
      const today = new Date;
      if (this.data.date_init > today){
        this.errorMessage += 'Fecha de inicio no puede ser futura. ';
      }
    }
    if (!this.data.contact.id){
      // Se crea un contacto nuevo.
      this.data.contact.email = this.data.contact.email?.trim();
      this.data.contact.phone = this.data.contact.phone?.trim();
      this.data.contact.dni = cleanDni(this.data.contact.dni)

      if (!this.data.contact.name){ this.errorMessage += 'Nombre requerido. '; }
      if (!this.data.contact.lastname){ this.errorMessage += 'Apellido requerido. '; }
      if (!this.data.contact.dni){ this.errorMessage += 'DNI requerido. '; }
      if (!this.data.contact.birthdate){ this.errorMessage += 'Fecha nacimiento requerida. '; }
      if (!this.data.contact.email){ 
        this.errorMessage += 'Email requerido. '; 
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.data.contact.email)) {
          this.errorMessage += 'Email inválido. ';
        }
      }
      if (!this.data.contact.phone){ 
        this.errorMessage += 'Teléfono requerido. '; 
      } else if (/\D/.test(this.data.contact.phone)) {
        this.errorMessage += 'Teléfono inválido. ';
      }

      if (!this.data.contact.pronoun){ this.errorMessage += 'Pronombre requerido. '; }
    }
    return !this.errorMessage;
  }

  onSearch(): void {
    this.data.contact.dni = cleanDni(this.data.contact.dni);
    if (!this.data.contact.dni){
      this.errorMessage = 'DNI requerido.'
      return;
    }
    this.errorMessage = '';

    this.contactFound = false
    this._isfService.getContactByDniAdmin(this.data.contact.dni).then((response: any) => {
      console.log(response);
      this.createMode = true;
      if (response.contact?.id){
        this.data.contact = response.contact;
        this.contactFound = true;
      }
    }).catch((error: any) => {
      console.log(error);
    });
  }

  onCreate(): void {

    // Validar fechas
    if (!this.inputsAreValid()){
      return;
    }

    let contactDto: ContactDto;
    if (this.data.contact.id){
      // Ya existe contacto en bd
      contactDto = {
        id: this.data.contact.id
      } as ContactDto;
    } else {
      // Crear nuevo contacto
      contactDto = {
        dni: cleanDni(this.data.contact.dni),
        name: this.data.contact.name,
        lastname: this.data.contact.lastname,
        email: this.data.contact.email,
        phone: this.data.contact.phone,
        birthdate: getDateStringForBackend(this.data.contact.birthdate),
        pronoun: this.data.contact.pronoun,
      } as ContactDto;
    }

    const dto = {
      role: this.data.role,
      date_init: getDateStringForBackend(this.data.date_init),
      contact: contactDto
    } as CreateParticipantDto;

    this._isfService.createParticipantOnProject(this.data.project_id, dto).then(
      (response) => {
        console.log(response);
        if (response) {
          SnackbarService.showSuccess('Participación creada!');
          this.dialogRef.close({createdParticipant: response});
        }
      }
    ).catch((error: any) => {
      console.error(error);
    });
  }

  onClose(): void {
    this.dialogRef.close(null);
  }
}

function cleanDni(dni:string) : string{
  return dni?.replace(/[^0-9]/g, '');
}

export interface CreateParticipantDialogData {
  project_id: string;
  role: string;
  date_init: Date;
  contact: {
    id: string;
    name: string;
    lastname: string;
    pronoun: string;
    dni: string;
    email: string;
    phone: string;
    birthdate: Date;
  };
}
