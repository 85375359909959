import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService} from './services/error-snackbar.service';



@Component({
  selector: 'app-root',
  template: `
    <router-outlet>
      <div *ngIf="isLoading">
        <div class="spinner_overlay"></div>
        <mat-spinner class="spinner"></mat-spinner>
      </div>
    </router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean;
  isLoading: boolean = false;
  private subscriptions: any[] = [];

  constructor(private authService: AuthService, private loaderService: LoaderService, private snackBar: MatSnackBar) { }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.loaderService.httpProgress().subscribe((status: boolean) => {
        this.isLoading = status
      })
    )
    this.subscriptions.push(


      SnackbarService.errorEvent.subscribe((errorMessage: string) => {
        // this.snackBar.open(error, 'close', { duration: 5000, panelClass: 'errorSnack' });
        this.snackBar.open(errorMessage, 'Cerrar', {
          duration: 0, // Duración infinita
          horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
          verticalPosition: 'top', // 'top' | 'bottom'
          panelClass: ['alert', 'alert-error'], // Clase CSS personalizada
        });
      }),

      SnackbarService.successEvent.subscribe((successMessage: string) => {
        this.snackBar.open(successMessage, 'Cerrar', {
          duration: 5000, // 5 segs
          horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
          verticalPosition: 'top', // 'top' | 'bottom'
          panelClass: ['alert', 'alert-success'], // Clase CSS personalizada
        });
      })
      



    )
    this.isLoggedIn = await this.authService.isLogin();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
