import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ContactDto, IsfService } from 'src/app/services/isf.service';
import {getDateDescription, getDateStringForBackend} from 'src/app/utils/utils';
import { environment } from '../../../environments/environment';
import { WorkSessionInfo } from '../work-session/work-session.component';
import { ParticipantDialogComponent, ParticipantDialogData } from '../participant/participant-dialog.component';
import { SnackbarService } from 'src/app/services/error-snackbar.service';
import { CreateParticipantDialogComponent, CreateParticipantDialogData } from '../participant/create-participant-dialog.component';

export interface ProjectInfo {
  name: string;
  location: string;
  date_init: string;
  date_end: string | null;
  status: string;
  id: string;
  participants_sync_date: string | null;
}

interface WorkSessionToken {
  work_session_id: string;
  work_session_name: string;
  token: string;
}

interface WorkSessionUrlToken {
  work_session_id: string;
  work_session_name: string;
  url: string;
}


@Component({
  selector: 'project-info',
  template: `
    <div id="projects-component" class="page-layout carded fullwidth inner-scroll">

      <!-- TOP BACKGROUND -->
      <div class="top-bg accent"></div>
      <!-- / TOP BACKGROUND -->

      <!-- CENTER -->
      <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

          <!-- BREADCRUMB -->
          <div class="breadcrumb">
            <mat-icon class="breadcrumb-icon">home</mat-icon>
            <a [routerLink]="['/cora/projects']" class="breadcrumb-link">Proyectos</a>
            <mat-icon class="breadcrumb-separator">chevron_right</mat-icon>
            <span class="breadcrumb-text">{{ projectName }}</span>
          </div>
          <!-- / BREADCRUMB -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <mat-tab-group>
          <mat-tab label="General">
            <div class="page-layout project-details" *ngIf="project != null">
              <div>Estado: <b>{{project.status}}</b></div>
              <div>Inicio: <b>{{dateDescription(project.date_init, false)}} -- Fin: {{dateDescription(project.date_end, false)}}</b></div>
              <div><a [href]=locationLink(project.location) target="_blank">{{project.location}}</a></div>
              <mat-expansion-panel class="next-work-sessions custom-expansion-panel-color">
                <mat-expansion-panel-header>
                  <mat-panel-title>Formulario a convocatoria de jornada</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card *ngIf="enrollmentLinks.length != 0">
                  <ng-container *ngFor="let link of enrollmentLinks">
                    <div class="enrollment-link">
                      <span>{{ link.work_session_name }}</span>
                      <span>
                        <mat-slide-toggle (change)="toggleWorkSessionEnrollment($event, link.work_session_id)" [checked]="link.url != null"></mat-slide-toggle>
                        <button mat-button [cdkCopyToClipboard]="link.url" [disabled]="link.url == null"><mat-icon>content_copy</mat-icon></button>
                      </span>
                    </div>
                  </ng-container>
                </mat-card>
                <mat-card *ngIf="enrollmentLinks.length == 0">
                  <div>No hay jornadas pendientes para este proyecto.</div>
                </mat-card>
              </mat-expansion-panel>
              <mat-card style="display:block" *ngIf="projectDetail != null">
                <h4>Jornadas</h4>
                <div style="padding:10px;">
                  <div>Realizadas: {{projectDetail['work_sessions']['done']}}</div>
                  <div>Próxima: {{nextWorkSessionDescription(projectDetail['work_sessions']['next'])}}</div>
                </div>
                <h4>Participantes</h4>
                <div style="padding:10px;">
                  <div>Activos: {{projectDetail['participants']['active']}}</div>
                  <div>Total: {{projectDetail['participants']['total']}}</div>
                </div>
              </mat-card>
            </div>

          </mat-tab>
          <mat-tab label="Jornadas">
            <div>
              <mat-slide-toggle class="slide-toggle" (change)="showAllWorkSessions($event)">Mostrar todas las jornadas</mat-slide-toggle>
            </div>

            <button mat-fab class="floating-button" (click)="onCreateWorkSession()"><mat-icon>add</mat-icon></button>

            <mat-card *ngIf="!hasWorkSessions()">No hay Jornadas disponibles para este proyecto.</mat-card>
            <div class="page-layout carded fullwidth inner-scroll" *ngIf="hasWorkSessions()">
              <table mat-table [dataSource]="workSessionsDataSourceToShow" class="projects-table mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Estado</th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <!-- dateInit Column -->
                <ng-container matColumnDef="dateInit">
                  <th mat-header-cell *matHeaderCellDef> Fecha</th>
                  <td mat-cell *matCellDef="let element"> {{dateDescription(element.implementation_date, false)}}</td>
                </ng-container>


                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Nombre</th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- enrolled Column -->
                <ng-container matColumnDef="enrolled">
                  <th mat-header-cell *matHeaderCellDef> Anotados</th>
                  <td mat-cell *matCellDef="let element"> {{element.detail['registered_participants'] == 0 ? '-' : element.detail['registered_participants']}} </td>
                </ng-container>

                <!-- confirmed Column -->
                <ng-container matColumnDef="confirmed">
                  <th mat-header-cell *matHeaderCellDef> Confirmados</th>
                  <td mat-cell *matCellDef="let element"> {{element.detail['confirmed_participants'] == 0 ? '-' : element.detail['confirmed_participants']}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="workSessionColumns"></tr>
                <tr mat-row class="clickeable" *matRowDef="let row; columns: workSessionColumns;" (click)="onClickHandler(row)"></tr>
              </table>
            </div>
            <!-- / CONTENT CARD -->
          </mat-tab>
          <mat-tab label="Participantes">
            <button mat-fab class="floating-button" (click)="onCreateParticipant()"><mat-icon>add</mat-icon></button>
            <div class="sync-container" *ngIf="project != null">
              <mat-icon>warning</mat-icon>
              <div>Última sincronizacion: {{dateDescription(project.participants_sync_date, true)}}</div>
              <button mat-button class="update-button" (click)="onSyncParticipants()">Actualizar</button>
            </div>
            <mat-card *ngIf="participantsDataSource.length == 0">Aún no hay participantes para este proyecto.</mat-card>
            <div class="page-layout carded fullwidth inner-scroll" *ngIf="participantsDataSource.length > 0">
              <table mat-table [dataSource]="participantsDataSource" class="projects-table mat-elevation-z8">

                <!-- lastname Column -->
                <ng-container matColumnDef="lastname">
                  <th mat-header-cell *matHeaderCellDef> Apellido</th>
                  <td mat-cell *matCellDef="let element"> {{element.contact.lastname}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Nombre</th>
                  <td mat-cell *matCellDef="let element"> {{element.contact.name}} </td>
                </ng-container>

                <!-- role Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef> Rol</th>
                  <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Estado</th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="participantColumns"></tr>
                <tr mat-row class="clickeable" *matRowDef="let row; columns: participantColumns; let i = index" (click)="onParticipantClick(row, i)"></tr>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- / CENTER -->

    </div>
  `,
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent {
  workSessionColumns: string[] = ['status', 'dateInit', 'name', 'enrolled', 'confirmed'];
  workSessionsDataSource: WorkSessionInfo[] = [];
  workSessionsDataSourceToShow: WorkSessionInfo[] = [];
  participantColumns: string[] = ['lastname', 'name', 'role', 'status'];
  participantsDataSource: any[] = [];

  project: ProjectInfo;
  projectDetail: any;
  enrollmentLinks: WorkSessionUrlToken[] = [];
  projectName: string | null = '';
  projectId: string | null = '';


  constructor(
    private _isfService: IsfService,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this.activatedRoute.params.subscribe(async (params) => {
      this.projectId = params['id'];
      this.projectName = params['name'];
      if (this.projectId) {
        try {
          const promises = [
            this._isfService.getAllWorksSessionsByProject(this.projectId, false),
            this._isfService.getAllParticipantsByProject(this.projectId),
            this._isfService.getProjectInfo(this.projectId),
            this._isfService.getEnrollmentTokensForProject(this.projectId),
          ];
          const [workSessionResponse, participantsResponse, projectInfoResponse, enrollmentTokenResponse] = await Promise.all(promises);
          this.workSessionsDataSource = workSessionResponse['work_sessions'];
          this.showAllWorkSessions(false);
          this.participantsDataSource = participantsResponse['participants'];
          this.project = projectInfoResponse['project'];
          this.projectDetail = projectInfoResponse['detail'];
          this.enrollmentLinks = enrollmentTokenResponse['tokens'].map((element: WorkSessionToken) => {
            let url = element.token == null ? element.token : `${environment.config.urlWeb}/work-session-form/${element.token}`;
            return {work_session_id: element.work_session_id, work_session_name: element.work_session_name , url: url};
          });
        } catch(error: any) {
          console.error(error);
        }
      }
    });
  }

  showAllWorkSessions(event: any) {
    this.workSessionsDataSourceToShow = event.checked ? this.workSessionsDataSource : this.workSessionsDataSource.filter((workSession) => {
      return workSession['status'] == 'Pending';
    });
  }

  toggleWorkSessionEnrollment(event: any, work_session_id: string) {
    let updateData: Record<string, any> = {work_session_id: work_session_id, active: event.checked}

    this._isfService.updateEnrollmentForWorkSession(this.project.id, updateData).then(
      (response: WorkSessionToken) => {
        this.enrollmentLinks = this.enrollmentLinks.map((element: WorkSessionUrlToken) => {
          if (element.work_session_id == work_session_id) {
            let url = response.token == null ? response.token : `${environment.config.urlWeb}/work-session-form/${response.token}`;
            return {work_session_id: response.work_session_id, work_session_name: response.work_session_name , url: url}
          }
          return element;
        });
      }
    ).catch((error: any) => {
      console.error(error);
    })
  }

  locationLink(location: string) {
    if (location == undefined) {
      return ''
    }

    let formatedLocation = location.replace(/ /gi, '+');
    let baseUrl = 'https://www.google.com.ar/maps/place'
    return `${baseUrl}/${formatedLocation}`;
  }

  dateDescription(date: string | null, time: boolean) {
    if (date == null) {
      date = ''
    }
    return getDateDescription(date, time)
  }

  nextWorkSessionDescription(workSession: any | null) {
    if (workSession == null) {
      return '-'
    }
    let date = this.dateDescription(workSession['implementation_date'], true)
    return `${workSession['name']} (${date})`;
  }

  hasWorkSessions() {
    return this.workSessionsDataSource.length > 0;
  }

  onClickHandler(row: any) {
    const workSessionId = row.id;
    let workSessionName = this._isfService.normalizeWorkSessionName(row.name, true);
    this._isfService.setActiveWorkSession(row);
    this._router.navigate(["/cora/work-session/" + workSessionName + "/" + workSessionId],
    { queryParams: {
      projectName: this.projectName,
      projectId: this.projectId
    }});
  }

  onSyncParticipants() {
    this._isfService.getAllParticipantsByProject(this.project.id, true).then(
      (response) => {
        this.participantsDataSource = response['participants'];
        this.project.participants_sync_date = String(new Date());
        SnackbarService.showSuccess('Sincronización completada!');
      }
    ).catch((error: any) => {
      console.error(error);
    });
  }

  onParticipantClick(row: any, index: number) {
    let dialogData = {} as ParticipantDialogData
    dialogData.contact = row.contact
    dialogData.originalParticipation = { role: row.role, status: row.status }
    dialogData.participation = {id: row.id, role: row.role, status: row.status}
    const dialogRef = this.dialog.open(ParticipantDialogComponent, {data: dialogData});

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return
      }
      // Actualizar tabla
      let aux = [...this.participantsDataSource]
      aux[index] = result.updatedParticipant
      this.participantsDataSource = aux
    });
  }

  onCreateParticipant() {
    let dialogData = {} as CreateParticipantDialogData;
    dialogData.role = 'Participante obra'
    dialogData.date_init = new Date
    dialogData.project_id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    dialogData.contact = { } as any // ContactDo
    const dialogRef = this.dialog.open(CreateParticipantDialogComponent, {data: dialogData});

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }

      // Agregar a la tabla
      const participant = result.createdParticipant
      let aux = [...this.participantsDataSource]
      aux.push(participant)
      this.participantsDataSource = aux
    });
  }

  createWorkSession(data: CreateWorkSessioDialogData) {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this._isfService.createWorkSession(id, data.name, data.date).then(
        (response) => {
          let wsession: WorkSessionInfo = response
          if (response) {
            // Force refresh rows
            SnackbarService.showSuccess('Jornada creada!');
            let aux = [...this.workSessionsDataSource]
            aux.unshift(wsession)
            this.workSessionsDataSource = aux
          }
          this.showAllWorkSessions(false);
        }
      ).catch((error: any) => {
        console.error(error);
      });
    }
  }

  onCreateWorkSession() {
    const dialogRef = this.dialog.open(CreateWorkSessionDialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return
      }
      this.createWorkSession(result)
    });
  }
}

/*
*
* Dialog for work session creation * * * * *
*
* */

export interface CreateWorkSessioDialogData {
  name: string;
  date: string;
}

@Component({
  selector: 'create-work-session-dialog',
  template: `
    <h1 mat-dialog-title>Creacion de nueva jornada</h1>
    <div mat-dialog-content class="dialog-content">
      <div class="alert alert-warning">
        Sólo se usarán los primeros 30 caracteres para impactar en salesforce. El resto sólo serán visibles en CORA.
      </div>
      <mat-form-field>
          <mat-label>Nombre de la jornada</mat-label>
          <input matInput [(ngModel)]="name">
      </mat-form-field>
      <mat-form-field>
        <input matInput readonly [matDatepicker]="datePicker" [(ngModel)]="date" placeholder="Fecha de la jornada">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Horario de la jornada</mat-label>
        <input matInput type="time" [(ngModel)]="time">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancelar</button>
        <button mat-button class="confirm-button" (click)="onCreate()" cdkFocusInitial>Crear</button>
    </div>
  `,
  styleUrls: ['./project-info.component.scss']
})
export class CreateWorkSessionDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateWorkSessionDialog>,
  ) {}
  name: string
  date: Date
  time: string = '09:00'

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  private formatDate(): string {
    let formatedDay = getDateStringForBackend(this.date)
    let formatedTime = `${this.time}:00`
    return `${formatedDay} ${formatedTime}`;
  }

  onCreate(): void {
    if (this.name == null || this.date == null || this.time == null) {
      this.dialogRef.close(null);
      return
    }
    let data: CreateWorkSessioDialogData = {name: this.name, date: this.formatDate()}
    this.dialogRef.close(data);
  }
}