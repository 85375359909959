import { Component, OnInit, Inject } from '@angular/core';
import { IsfService } from 'src/app/services/isf.service';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';


import {getDateDescription} from 'src/app/utils/utils';
import { SnackbarService } from 'src/app/services/error-snackbar.service';
import { UpdateWorkSessionNameDialogComponent, UpdateWorkSessionNameDialogData } from './update-work-session-name-dialog.component';

export interface ParticipantInfo {
  id: string;
  contact: {
    id: string;
    name: string;
    lastname: string;
  }
  role: string;
  status: string;
}

export interface ParticipantAttendance {
  participant_id: string;
  name: string;
  lastname: string;
  role: string;
  diet: string;
  transport: string;
  location: string;
  comments: string;
  status: string;
}

export interface WorkSessionInfo {
  name: string;
  implementation_date: string;
  status: string;
  is_sync: boolean;
  id: string;
  project_id: string;
  detail: any;
}


@Component({
  selector: 'work-session',
  template: `
   <div id="projects-component" class="page-layout carded fullwidth inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

      <!-- HEADER -->
      <div class="header accent">
        <!-- BREADCRUMB -->
        <div class="breadcrumb">
          <mat-icon class="breadcrumb-icon">home</mat-icon>
          <a [routerLink]="['/cora/projects']" class="breadcrumb-link">Proyectos</a>
          <mat-icon class="breadcrumb-separator">chevron_right</mat-icon>
          <a [routerLink]="[this.projectLink]" class="breadcrumb-link">{{ this.projectName }}</a>
        </div>
        <!-- / BREADCRUMB -->
      </div>
      <!-- / HEADER -->
      <!-- APP TITLE -->
      <div class="work-session-header">
        <h2 class="logo-text">
          {{this.workSessionName}} <button (click)="onUpdateWorkSessionName()"><mat-icon>edit</mat-icon></button>
        </h2>
        <span class="work-session-calendar">
            <span class="work-session-calendar-detail">
                <mat-icon class="icon-margin">calendar_today</mat-icon>
                <span>{{dateDescription(this.activeWorkSession.implementation_date)}}</span>
            </span>
        </span>
      </div>
        <!-- / APP TITLE -->
      <div class="work-session-info">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header><mat-panel-title>Datos de transporte</mat-panel-title></mat-expansion-panel-header>
            <span class="work-session-attribute">
              <mat-icon class="material-icons icon-margin">{{workSessionAttributes.enrolled.icon}}</mat-icon>
              <span>{{workSessionAttributes.enrolled.text}}</span>
              <mat-icon class="material-icons icon-margin">{{workSessionAttributes.present.icon}}</mat-icon>
              <span>{{workSessionAttributes.present.text}}</span>
              <mat-icon class="material-icons icon-margin">{{workSessionAttributes.cars.icon}}</mat-icon>
              <span>{{workSessionAttributes.cars.text}} ||</span>
              <span>Necesitan transporte: {{workSessionAttributes.needs_ride}}</span>
            </span>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header><mat-panel-title>Datos de almuerzo</mat-panel-title></mat-expansion-panel-header>
            <span class="work-session-attribute">
              <mat-icon class="material-icons icon-margin">{{workSessionAttributes.meal.icon}}</mat-icon>
              <span>{{workSessionAttributes.meal.text}} ||</span>
              <span>Sin restricción alimenticia: {{workSessionAttributes.diet.no_restriction}}</span>
              <span>| Sin TACC: {{workSessionAttributes.diet.gluten_free}}</span>
              <span>| Vegetarianos/as: {{workSessionAttributes.diet.veggie}}</span>
              <span>| Veganos/as: {{workSessionAttributes.diet.vegan}}</span>
            </span>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select class="status-mat-select" [(ngModel)]="activeWorkSession.status" (selectionChange)="onUpdateStatus($event)">
            <mat-option *ngFor="let option of workSessionStatusOptions" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="save-attendance-container">
        <div class="sync-attendance-container">
          <mat-icon class="material-icons icon-margin">{{this.canEditAttributes.get(canEditAttendance())?.icon}}</mat-icon>
          <span>{{this.canEditAttributes.get(canEditAttendance())?.text}}</span>
        </div>
        <button mat-button [disabled]="!canEditAttendance()" (click)="onSaveAttendance()" class="attendance-button">Registrar asistencia</button>
      </div>

      <!-- CONTENT CARD -->
      <div class="page-layout carded fullwidth inner-scroll">
        <button mat-fab class="floating-button" (click)="onAddParticipant()"><mat-icon>add</mat-icon></button>
        <table mat-table [dataSource]="dataSource" class="projects-table mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

          <!-- name and last name Column -->
          <ng-container matColumnDef="full_name">
            <th mat-header-cell *matHeaderCellDef> Nombre y Apellido </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} {{element.lastname}}</td>
          </ng-container>

          <!-- role Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef class="hide-mobile"> Rol </th>
            <td mat-cell *matCellDef="let element" class="hide-mobile"> {{element.role}} </td>
          </ng-container>


          <!-- diet Column -->
          <ng-container matColumnDef="diet">
            <th mat-header-cell *matHeaderCellDef> Almuerzo </th>
            <td mat-cell *matCellDef="let element"> {{element.diet}} </td>
          </ng-container>

          <!-- location Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef class="hide-mobile">  Ubicación  </th>
            <td mat-cell *matCellDef="let element" class="hide-mobile"> {{element.location}} </td>
          </ng-container>

          <!-- location Column -->
          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef class="hide-mobile">  Detalles  </th>
            <td mat-cell *matCellDef="let element" class="hide-mobile"> {{element.comments}} </td>
          </ng-container>

          <!-- transport Column -->
          <ng-container matColumnDef="transport">
            <th mat-header-cell *matHeaderCellDef class="hide-mobile">  Transporte  </th>
            <td mat-cell *matCellDef="let element" class="hide-mobile"> {{element.transport}} </td>
          </ng-container>

          <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef> Asistencia </th>
            <td mat-cell *matCellDef="let element">
              <mat-select [disabled]="!canEditAttendance()" (selectionChange)="onUpdateAttendance(element, $event)" [(value)]="element.status" >
                <mat-option *ngFor="let option of attendanceOptions" [value]="option"> {{option}} </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->

  </div>`,
  styleUrls: ['./work-session.component.scss']
})

export class WorkSessionComponent {
  displayedColumns: string[] = ['full_name', 'role', 'diet', 'location', 'comments', 'transport', 'confirmed'];
  dataSource: ParticipantAttendance[];
  workSessionAttributes = {
    enrolled: {icon: 'person', text: '-', description: 'number of people enrolled'},
    present: {icon: 'how_to_reg', text: '-', description: 'number of present attendees'},
    cars: {icon: 'directions_car', text: '-', description: 'number of people with available cars'},
    needs_ride: '-',
    meal: {icon:'restaurant', text: '-', description:'number of people who needs a meal in place'},
    diet: {no_restriction: '-', gluten_free: '-', veggie: '-', vegan: '-'}
  };
  canEditAttributes = new Map([
    [false, {icon: 'cloud_done', text: 'Si querés actualizar la asistencia, tenés que cambiar el estado de la jornada a pendiente'}],
    [true, {icon: 'cloud_off', text: 'Si querés asentar las asistencias en SalesForce, tenés que cambiar el estado de la jornada a completada'}]
  ]);
  projectName: string;
  projectLink: string;
  activeWorkSession: WorkSessionInfo;
  workSessionName: string;
  updatedAttendance: Map<string, string>;
  attendanceOptions: string[] = ["Presente", "Ausente"];
  workSessionStatusOptions: string[] = ["Pending", "Complete", "Canceled"];


  constructor(
    private _isfService: IsfService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
        this.projectName = params.projectName;
        this.projectLink = "/cora/project/" + this.projectName + "/" + params.projectId;
    });
    this.activatedRoute.params.subscribe(async (params) => {
      try {
        const id = params['workSessionId'];
        this.activeWorkSession = this._isfService.getActiveWorkSession();
        this.workSessionName = this._isfService.normalizeWorkSessionName(this.activeWorkSession.name);
        this.updatedAttendance = new Map<string, string>();

        const promises = [
          this._isfService.getWorkSessionParticipantsById(id || ''),
          this._isfService.getWorkSessionInfo(id || '')
        ];

        const [participants, workSessionInfo] = await Promise.all(promises);
        this.dataSource = participants["attendance"];
        this.workSessionAttributes.enrolled.text = workSessionInfo["attendees"]["enrolled"]
        this.workSessionAttributes.present.text = workSessionInfo["attendees"]["attended"]
        this.workSessionAttributes.meal.text = workSessionInfo["attendees_diet"]["total"]
        this.workSessionAttributes.diet = workSessionInfo["attendees_diet"]
        this.workSessionAttributes.needs_ride = workSessionInfo["attendees"]["need_ride"]
        this.workSessionAttributes.cars.text = workSessionInfo["attendees"]["available_cars"]
      } catch (error: any) {
        console.error(error);
      }
  });
}

  dateDescription(date: string) {
    return getDateDescription(date, true).split(' ')[0];
  }

  private getUpdatedAttendance() {
    let attendance = Array()

    this.updatedAttendance.forEach((val, mapKey) => {
      let att = new Map([
        ["participant_id", mapKey],
        ["status", val]
      ])
      attendance.push(att)
    });

    return attendance
  }

  canEditAttendance(): boolean {
    return this.activeWorkSession.status == 'Pending';
  }

  onSaveAttendance() {
    let attendance = this.getUpdatedAttendance()

    if (attendance.length == 0) {
      return
    }

    this._isfService.saveWorkSessionAttendance(
      this.activeWorkSession.id,
      attendance
    ).then(
      (response) => {
        SnackbarService.showSuccess('Asistencias registradas en CORA! (Para que impacte en Salesforce, cambiar Status a Complete)');
        this.activeWorkSession.is_sync = response['synchronized']
        this.updatedAttendance = new Map<string, string>();
      }
    ).catch((error: any) => {
      console.error(error);
    })
  }

  onUpdateAttendance(element: ParticipantAttendance, event: any) {
    this.updatedAttendance.set(element.participant_id, event.value.toString())
  }

  onUpdateStatus(event: any) {
    try {
      this._isfService.updateWorkSession(this.activeWorkSession.id, { 
        status: event.value.toString()
      }).then((response) => {
        if (response){
          SnackbarService.showSuccess('Estado actualizado! (impactado en Salesforce)');
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  onAddParticipant() {
    this._isfService.getAllParticipantsByProject(this.activeWorkSession.project_id).then(
      (response) => {
        let dialogData = {} as ParticipantsDialogData
        dialogData.participants = response['participants'].filter((participant: ParticipantInfo) => {
          return participant.status == 'active';
        })
        const dialogRef = this.dialog.open(AddParticipantDialog, {data: dialogData});

        dialogRef.afterClosed().subscribe(result => {
          if (result == null) {
            return
          }
          console.log("cerraron el dialog con " + result.contact.id)

          this._isfService.addParticipantToWorkSession(this.activeWorkSession.id,result.contact.id).then(
            (response) => {
              // Force refresh rows
              SnackbarService.showSuccess('Participante agregado a la jornada!');
              let aux = [...this.dataSource]
              aux.push(response as ParticipantAttendance)
              this.dataSource = aux
            }
          ).catch((error: any) => {
            console.error(error);
          })

        });

      }
    ).catch((error: any) => {
      console.error(error);
    })
  }

  onUpdateWorkSessionName() {
    const dialogData = {
      work_session_id: this.activeWorkSession.id,
      old_name: this.workSessionName
    } as UpdateWorkSessionNameDialogData;

    const dialogRef = this.dialog.open(UpdateWorkSessionNameDialogComponent, {data: dialogData});

      dialogRef.afterClosed().subscribe(result => {
        if (result == null) {
          return
        }
        this.workSessionName = result.new_name;
      });
  }
}

/*
*
* Dialog for participant selection * * * * *
*
* */

export interface ParticipantsDialogData {
  participants: ParticipantInfo[];
}

@Component({
  selector: 'add-participant-dialog',
  template: `
    <h1 mat-dialog-title>Agregar participante a la jornada</h1>
    <div class="alert alert-warning">
      Si el/la participante aún no existe dentro del proyecto, primero agregarlo desde el listado de participantes del proyecto. Luego agregarlo a la jornada.
    </div>
    <div mat-dialog-content class="custom-dialog">
      <mat-form-field>
        <mat-label>Participante</mat-label>
        <input matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='setSelected($event)'>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ displayFn(option)}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancelar</button>
        <button mat-button class="confirm-button" (click)="onCreate()" cdkFocusInitial>Agregar</button>
    </div>
  `,
  styleUrls: ['./work-session.component.scss']
})
export class AddParticipantDialog implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<ParticipantInfo[]>;
  selectedParticipant: ParticipantInfo;

  constructor(
    public dialogRef: MatDialogRef<AddParticipantDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ParticipantsDialogData,
  ) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.contact.name;
        return name ? this._filter(name as string) : this.data.participants;
      }),
    );
  }

  displayFn(participant: ParticipantInfo): string {
    return participant ? `${participant.contact.name} ${participant.contact.lastname}` : ''
  }

  private _filter(name: string): ParticipantInfo[] {
    const filterValue = name.toLowerCase();
    return this.data.participants.filter(option => {
      return option.contact.name.toLowerCase().includes(filterValue) || option.contact.lastname.toLowerCase().includes(filterValue)
    });
  }

  setSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedParticipant = event.option.value
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onCreate(): void {
    this.dialogRef.close(this.selectedParticipant);
  }
}